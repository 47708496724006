import React from "react"
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import Img from 'gatsby-image'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      contactPerson {
        id
        highlightedMember
        memberMoreInfo {
          name
          id
          title
          photo {
            fixed(width: 500, height: 500, imgixParams: {crop: "faces", ar: "1", fit: "crop"}) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function Assets({ data, pageContext }) {
  const { contactPerson } = data.datoCmsInvestorRelationsPage
  const contactPersonInfo = contactPerson[0].memberMoreInfo
  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
      <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <PageTitle />
            <div>
              <div className="grid ir_contact">
                <div className="col col--md-4"><Img fluid={contactPersonInfo.photo.fixed} className="border-radius-full margin-bottom-small" /></div>
                <div className="col col--md-8">
                  <strong className="block">
                    {contactPersonInfo.name}
                  </strong>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: contactPerson[0].highlightedMember,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}
const PageTitle = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <h1>{language=='sv' ? 'Kontakt' : 'Contact'}</h1>
  )
}